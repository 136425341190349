import { yupResolver } from '@hookform/resolvers/yup';
import { Moment } from 'moment';
import React, {
  ChangeEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import { useAppSelector } from '../../app/hook';
import {
  DailyRepeatConstants,
  IAddNewDiningMealsPayload,
  IDiningDynamicInput,
  IDiningItemsPayload,
  IRepeatFormSchema,
  IRestaurants,
  IWeekdaysCheckbox,
  IWeekdaysConstants,
  ItemTypeConstants,
} from '../../pages/diningPage/diningInterface';
import '../diningForm/diningForm.css';
import ItemsParentCardForm from '../diningForm/itemParentCardForm';
import LoadingSpinner from '../loadingSpinner/loadingSpinner';
import { WeedDays } from './modalConstants';
import { repeatFormSchema } from './repeatFormValidation';

const AddNewRepeat: React.FC<{
  currentDate: Moment;
  restaurantId: string;
  isAdding: boolean;
  onCreate: (data: IAddNewDiningMealsPayload, date: string) => void;
  restaurant: IRestaurants;
}> = ({ currentDate, restaurantId, isAdding, onCreate, restaurant }) => {
  const [translation] = useTranslation('common');
  const elementRef: any = useRef();
  const [weekdayCheckbox, setWeekdayCheckbox] = useState<IWeekdaysCheckbox[]>(
    []
  );
  const [categoryId, setCategoryId] = useState<number | null>();
  const [weekdaysList, setWeekdaysList] = useState<string[]>([]);
  const [itemRepeatDaily, setItemRepeatDaily] = useState<string>(
    DailyRepeatConstants.repeat
  );
  const [presentDate, setPresentDate] = useState<string>(
    currentDate.format('YYYY-MM-DD')
  );
  const { categories } = useAppSelector((state) => state.dining);

  const [diningItems, setDiningItems] = useState<IDiningItemsPayload[]>([
    {
      id: Math.random().toString(),
      title: '',
      price: '',
      description: '',
      type: ItemTypeConstants.exclusive,
    },
  ]);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<IRepeatFormSchema>({
    resolver: yupResolver(repeatFormSchema),
    defaultValues: {},
  });
  const addMoreInputHandler = (e: MouseEvent<HTMLButtonElement>) => {
    const newField = {
      id: Math.random().toString(),
      title: '',
      price: '',
      description: '',
      type: ItemTypeConstants.exclusive,
    };
    setDiningItems([...diningItems, newField]);
  };
  const AlwaysScrollToBottom = () => {
    elementRef?.current?.scrollIntoView();
  };

  useEffect(() => {
    AlwaysScrollToBottom();
  }, [diningItems]);

  const activeItemTypeButton = (type: string, id?: string) => {
    const itemsArray = [...diningItems];
    const newItemsArray = itemsArray.map((item: IDiningDynamicInput) => {
      if (item.id == id) {
        return {
          ...item,
          type,
        };
      }
      return item;
    });
    setDiningItems([...newItemsArray]);
  };

  const itemRepeatDailyHandler = (type: string) => {
    setItemRepeatDaily(type);
  };
  const presentDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPresentDate(e.target.value);
  };
  const handleCheckboxChange = (e: any) => {
    const { checked, id } = e.target;
    const newValue: IWeekdaysCheckbox = { id, value: id, checked: checked };
    const newWeeksArray: IWeekdaysCheckbox[] = [...weekdayCheckbox];
    const finalArray: IWeekdaysCheckbox[] = newWeeksArray.filter(
      (item: IWeekdaysCheckbox) => item.id != newValue.id
    );
    finalArray.push(newValue);
    const onlyTrueValueArray: IWeekdaysCheckbox[] = finalArray.filter(
      (item: IWeekdaysCheckbox) => item.checked == true
    );
    setWeekdayCheckbox([...onlyTrueValueArray]);
    const weekDaysArray: string[] = onlyTrueValueArray.map(
      (item: IWeekdaysCheckbox) => {
        return item.value;
      }
    );
    setWeekdaysList([...weekDaysArray]);
  };
  const getData = (
    name: string,
    value: string,
    index: number,
    itemId?: string
  ) => {
    const valueArray: IDiningDynamicInput[] = [...diningItems];
    const valueIndex: number = valueArray.findIndex(
      (item: IDiningDynamicInput) => item.id == itemId
    );
    valueArray[valueIndex] = {
      ...valueArray[valueIndex],
      [name]: value,
    };
    setDiningItems([...valueArray]);
  };

  const removeItemClick = (
    itemIndex: number,
    selectedItem: IDiningDynamicInput,
    id?: string
  ) => {
    const clone = [...diningItems];
    if (clone.length > 1) {
      clone.splice(itemIndex, 1);
      setDiningItems(clone);
    }
  };

  const onSubmit = (data: IRepeatFormSchema) => {
    let payload = {} as IAddNewDiningMealsPayload;

    if (itemRepeatDaily == DailyRepeatConstants.date) {
      payload = {
        title: data?.name,
        categoryId: categoryId as number,
        startTime: data?.startTime,
        endTime: data?.endTime,
        restaurantId,
        date: presentDate,
        diningItems: [
          ...diningItems.map((item: IDiningDynamicInput) => {
            item.id = '0';
            return item;
          }),
        ],
      };
    } else if (itemRepeatDaily == DailyRepeatConstants.repeat) {
      if (weekdaysList.length == 0) {
        toast.warning('Please select Repeating Days');
        return;
      }
      payload = {
        title: data?.name,
        startTime: data?.startTime,
        categoryId: categoryId as number,
        endTime: data?.endTime,
        restaurantId,
        repeatable: true,
        weekdaysList: [...weekdaysList],
        // date: presentDate,
        diningItems: [
          ...diningItems.map((item: IDiningItemsPayload) => {
            item.id = '0';
            return item;
          }),
        ],
      };
    }

    onCreate(payload, presentDate);
  };

  return (
    <Form className="form width-100" onSubmit={handleSubmit(onSubmit)}>
      <div className="dining-modal-div">
        <div className="modal-left-div">
          <h2 className="add-items">{translation(`RESTAURANT.ADD_ITEMS`)}</h2>
          <div className="diningItems-scroll-container">
            {diningItems.length && (
              <ItemsParentCardForm
                getData={getData}
                removeItemClick={removeItemClick}
                diningItems={diningItems}
                activeItemTypeButton={activeItemTypeButton}
              />
            )}
            <div ref={elementRef} />
          </div>
          <span className="addMore pointer" onClick={addMoreInputHandler}>
            {translation(`RESTAURANT.ADD_MORE`)}
          </span>
        </div>
        <div className="modal-right-div">
          <h2 className="add-items">
            {' '}
            {translation(`RESTAURANT.TIME_AND_TITLE`)}
          </h2>
          <div className="modal-right-wrap">
            <FormGroup>
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <Input
                    placeholder={translation(`RESTAURANT.MEAL_NAME`)}
                    className="form-input shadow-none"
                    invalid={errors?.name ? true : false}
                    {...field}
                  />
                )}
              />
              <FormFeedback>
                {translation(`${errors?.name?.message}`)}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Input
                className="form-input shadow-none repeatType-dropdown"
                name="categoryId"
                type="select"
                value={categoryId as number}
                onChange={(e) => setCategoryId(e.target.value as any)}
              >
                <option key={0} value={0}>
                  {translation(`AMENITIES_FORM.SELECT_NONE`)}
                </option>
                {categories.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item?.categoryName}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <div className="date-time-parent-div">
              <FormGroup className="date-time-div">
                <Label className="input-label">
                  {' '}
                  {translation(`ACTIVITIES.START_TME`)}
                </Label>
                <Controller
                  control={control}
                  name="startTime"
                  render={({ field }) => (
                    <Input
                      type="time"
                      min={restaurant?.startTime}
                      placeholder="--:--"
                      className="dining-date-input shadow-none pointer"
                      invalid={errors?.startTime ? true : false}
                      {...field}
                    />
                  )}
                />
                <FormFeedback>
                  {translation(`${errors?.startTime?.message}`)}
                </FormFeedback>
              </FormGroup>
              <FormGroup className="date-time-div">
                <Label className="input-label">
                  {' '}
                  {translation(`ACTIVITIES.END_TIME`)}
                </Label>
                <Controller
                  control={control}
                  name="endTime"
                  render={({ field }) => (
                    <Input
                      type="time"
                      // min={minTime()}
                      max={restaurant?.endTime}
                      // min={startDate == endDate ? minTime() : ''}
                      placeholder="--:--"
                      className="dining-date-input shadow-none pointer"
                      invalid={errors?.endTime ? true : false}
                      {...field}
                    />
                  )}
                />
                <FormFeedback>
                  {translation(`${errors?.endTime?.message}`)}
                </FormFeedback>
              </FormGroup>
            </div>
          </div>
          <h2 className="add-items">
            {' '}
            {translation(`RESTAURANT.SELECT_DAYS`)}
          </h2>
          <div className="item-type-buttonGroup">
            <div
              className={
                itemRepeatDaily == DailyRepeatConstants.date
                  ? 'exclusiveItem-button'
                  : 'normalItem-button'
              }
              onClick={() => itemRepeatDailyHandler(DailyRepeatConstants.date)}
            >
              {translation(`RESTAURANT.DATE`)}
            </div>
            <div
              className={
                itemRepeatDaily == DailyRepeatConstants.repeat
                  ? 'exclusiveItem-button'
                  : 'normalItem-button'
              }
              onClick={() =>
                itemRepeatDailyHandler(DailyRepeatConstants.repeat)
              }
            >
              {translation(`RESTAURANT.REPEAT`)}
            </div>
          </div>
          {itemRepeatDaily == DailyRepeatConstants.repeat && (
            <div className="weekday-checkbox-div">
              {WeedDays.map((item: IWeekdaysConstants, index: number) => (
                <label
                  key={index}
                  htmlFor={item.value}
                  className="weekday-checkbox"
                >
                  <input
                    type="checkbox"
                    id={item.value}
                    checked={
                      item.value ==
                      weekdaysList
                        .filter((day: string) => item.value == day)
                        .toString()
                    }
                    className="pointer"
                    onChange={(e) => handleCheckboxChange(e)}
                  />
                  {item.content}
                </label>
              ))}
            </div>
          )}
          {itemRepeatDaily == DailyRepeatConstants.date && (
            <div className="addMealType-date-element">
              <Input
                type="date"
                value={presentDate}
                className="dining-date-input shadow-none pointer"
                min={new Date().toISOString().split('T')[0]}
                onChange={(e) => presentDateChange(e)}
              />
            </div>
          )}

          <div className="modal-submit-button-div">
            <Button type="submit" className="form-button shadow-none">
              {isAdding ? (
                <LoadingSpinner />
              ) : (
                translation(`AMENITIES_FORM.SAVE_UPDATE`)
              )}
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default AddNewRepeat;
